import { Stack, styled } from '@mui/material';

// --------------------------------------------------------------

export const StyledContainer = styled(Stack)(() => ({
  backgroundColor: '#222222',

  '@media (min-width: 600px)': {
    flexDirection: 'row',
  },
}));

export const StyledSitemapContainer = styled(Stack)(() => ({
  border: '1px solid #FEA550',
  margin: '10px',
  padding: '10px',
  borderRadius: '30px',
  width: '400px',
}));

export const SyledContent = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  flex: 1,
  gap: 30,
}));

export const StyledSocialMidiaIcon = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 10,
  flexWrap: 'wrap',
}));

export const StyledCopyright = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  height: '50px',
  overflow: 'hidden',
  alignItems: 'center',
  padding: '20px',
  flexWrap: 'wrap',
  alignContent: 'center',
}));

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

// ---------------------------------------------------------------------

export const StyledContainer = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',

  '@media (max-width: 750px)': {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
}));

export const StyledImage = styled('img')({
  width: '350px',
  objectFit: 'cover',

  '@media (max-width: 750px)': {
    width: '100%',
  },
});

// ---------------------------------------------------------------------

export interface CourseCardProps {
  src: string;
  alt: string;
  title: string;
  buttonTitle: string;
  link?: string;
  description: string;
  topics: string[];
  recorded?: boolean;
  live?: boolean;
  liveDescription?: string;
  value?: string;
  installments?: string;
  valueInstallments?: string;
  totalHours?: string;
  disableCount?: boolean;
}

// ---------------------------------------------------------------------

export default function CourseCard({
  src,
  alt,
  title,
  buttonTitle,
  link,
  description,
  topics,
  recorded,
  live,
  liveDescription,
  value,
  installments,
  valueInstallments,
  totalHours,
  disableCount,
}: CourseCardProps) {
  const theme = useTheme();

  return (
    <Card>
      <StyledContainer bgcolor={theme.palette.background.paper}>
        <StyledImage src={src} alt={alt} />

        <CardContent>
          <Stack spacing={2}>
            <Stack
              direction="row"
              spacing={2}
              flexWrap="wrap"
              color={theme.palette.text.primary}
              justifyContent={{ xs: 'center', sm: 'flex-start' }}
            >
              <Typography variant="h4">{title}</Typography>
              {recorded && <Chip label="Aulas Gravadas" color="default" />}
              {live && <Chip label="Presencial por Videoconferência" color="default" />}
            </Stack>

            {!disableCount && (
              <Stack direction="row" spacing={5} mt={3}>
                <Stack>
                  <Typography variant="h6">
                    R$ {value} à vista ou {installments}x de
                  </Typography>
                  <Typography variant="h3" color={theme.palette.primary.main}>
                    R$ {valueInstallments}
                  </Typography>
                  <Typography variant="h6">No cartão de crédito ou Boleto</Typography>
                </Stack>

                <Stack alignSelf="flex-end">
                  <Typography variant="h6">Duração</Typography>
                  <Typography variant="h3" color={theme.palette.primary.main}>
                    {totalHours}h
                  </Typography>
                  <Typography variant="h6">Suporte com o Tutor</Typography>
                </Stack>
              </Stack>
            )}

            <Typography
              variant="body1"
              component="div"
              textAlign="justify"
              dangerouslySetInnerHTML={{ __html: description }}
            />

            <Typography variant="h6" gutterBottom mt={2} textAlign="center">
              O que você irá aprender neste curso:
            </Typography>

            <br />
            {topics.map((item, index) => (
              <React.Fragment key={index}>
                {item}
                <br />
              </React.Fragment>
            ))}

            {liveDescription && (
              <Box width="100%" height="5px" bgcolor="#FEA550" my={2} borderRadius={10} />
            )}

            {liveDescription && (
              <Typography
                variant="h6"
                component="div"
                align="center"
                color="red"
                dangerouslySetInnerHTML={{ __html: liveDescription }}
              />
            )}

            <Box width="100%" height="5px" bgcolor="#FEA550" my={2} borderRadius={10} />

            <Link to={link || '#'}>
              <Button variant="contained" fullWidth>
                {buttonTitle}
              </Button>
            </Link>
          </Stack>
        </CardContent>
      </StyledContainer>
    </Card>
  );
}

import { Button, Stack } from '@mui/material';
import { PATH_DEVELOPMENT } from 'src/routes/paths';
import { LinkFormatted, TypographyFormatted } from '../LinkFormatted';
import { StyledSitemapContainer, SyledContent } from './styles';

// ----------------------------------------------------------------------------------

const contentSoftwareDevelopment = [
  { src: PATH_DEVELOPMENT.frontend, label: 'Desenvolvimento Front-End' },
  { src: PATH_DEVELOPMENT.backend, label: 'Desenvolvimento Back-End' },
  { src: PATH_DEVELOPMENT.devops, label: 'DevOps' },
  { src: '#', label: 'Cloud Computting' },
  { src: '#', label: 'Banco de Dados' },
  { src: '#', label: 'Solução de Bugs' },
  { src: '#', label: 'Criação de Lojas Virutais' },
  { src: '#', label: 'Certificação SSH' },
];

const contentDigitalMarketing = [
  { src: '#', label: 'Agenciamento' },
  { src: '#', label: 'Gestão de Tráfego' },
  { src: '#', label: 'Gestão do Ad-Sense' },
  { src: '#', label: 'Criação de Sites Profissionais' },
  { src: '#', label: 'Aprimoramento de Sites existentes' },
  { src: '#', label: 'WebDesign' },
  { src: '#', label: 'Google Analytics' },
  { src: '#', label: 'Criação de Logomarca e Identidade Visual' },
  { src: '#', label: 'Deploy de Aplicações' },
  { src: '#', label: 'E-mails profissionais' },
  { src: '#', label: 'Compra e gerenciamento de domínios' },
];

const contentCourses = [
  { src: '#', label: 'Cursos Online' },
  { src: '#', label: 'Cursos Presenciais' },
  { src: '#', label: 'Cursos Ao Vivo' },
  { src: '#', label: 'Palestras e Treinamentos' },
];

const contentSystems = [
  { src: '#', label: 'Sistemas ERP' },
  { src: '#', label: 'Sistema CRM' },
  { src: '#', label: 'Sistemas Financeiros' },
  { src: '#', label: 'Sistemas Personalizados' },
];

// ----------------------------------------------------------------------------------

export default function ContainerRow() {
  return (
    <Stack id="container-column" m={5}>
      <SyledContent>
        <StyledSitemapContainer>
          <TypographyFormatted label="Desenvolvimento de Software" />
          <LinkFormatted content={contentSoftwareDevelopment} />
        </StyledSitemapContainer>

        <StyledSitemapContainer>
          <TypographyFormatted label="Marketing Digital" />
          <LinkFormatted content={contentDigitalMarketing} />
        </StyledSitemapContainer>

        <StyledSitemapContainer>
          <TypographyFormatted label="Cursos & Palestras" />
          <LinkFormatted content={contentCourses} />
        </StyledSitemapContainer>

        <StyledSitemapContainer>
          <TypographyFormatted label="Sistemas Empresariais" />
          <LinkFormatted content={contentSystems} />
        </StyledSitemapContainer>
      </SyledContent>
    </Stack>
  );
}

import { Stack, styled, Tooltip } from '@mui/material';
import { Animate } from 'src/features/global/styles';
import CustomIconify from '../customIconify';

// ------------------------------------------------------------------------------

export const StyledSocialMidiaIcon = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 10,
  flexWrap: 'wrap',
}));

// ------------------------------------------------------------------------------

interface Props {
  content: {
    tooltip: string;
    icon: string;
    url: string;
  }[];
}

// ------------------------------------------------------------------------------

export default function SocialMedia({ content }: Props) {
  return (
    <StyledSocialMidiaIcon>
      {content.map((data, index) => (
        <Stack zIndex={index}>
          <Animate>
            <Tooltip title={data.tooltip} placement="bottom">
              <CustomIconify icon={data.icon} href={data.url} />
            </Tooltip>
          </Animate>
        </Stack>
      ))}
    </StyledSocialMidiaIcon>
  );
}

export const socialMediaContent = [
  {
    tooltip: 'Facebook',
    icon: 'logos:facebook',
    url: 'https://www.facebook.com/fullcodedev01',
  },
  {
    tooltip: 'Instagram',
    icon: 'skill-icons:instagram',
    url: 'https://www.instagram.com/fullcode_dev/',
  },
  {
    tooltip: 'LinkedIn',
    icon: 'devicon:linkedin',
    url: 'https://www.linkedin.com/company/fullcodesoftec/?viewAsMember=true',
  },
  {
    tooltip: 'Youtube',
    icon: 'logos:youtube-icon',
    url: 'https://www.youtube.com/@tiagovesperin',
  },
];

import { Box, Button, ButtonGroup, Stack, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import ScrollProgress from 'src/components/scroll-progress';
import AccordionList from 'src/features/components/accordionList';
import CourseCard from 'src/features/components/courseCard';
import WhatsAppPopup from 'src/features/components/whatsAppPopup';
import { CompanyName, TextContainer } from 'src/features/global';
import { PATH_PAGE } from 'src/routes/paths';
import { coursesData } from './coursesArr';

// -------------------------------------------------------------------------------------------------

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '30px',

  '@media (max-width: 750px)': {
    width: '100%',
  },
}));

export const StyledButtonGroup = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
}));

export const StyledContainerCourse = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
}));

// -------------------------------------------------------------------------------------------------

export default function CursoAoVivo() {
  const [activeButton, setActiveButton] = useState('Front-End');

  const titlePage = 'Cursos ao Vivo';

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <StyledBox>
        <Helmet>
          <title>
            {titlePage} | {CompanyName}
          </title>
        </Helmet>

        <ScrollProgress />

        <TextContainer>
          <Stack width="100%" gap={2} mb={5} padding={{ xs: '20px', sm: '0px' }}>
            <Stack mt={4}>
              <CustomBreadcrumbs
                heading="Cursos Ao Vivo"
                links={[
                  { name: 'Início', href: PATH_PAGE.home },
                  { name: 'Cursos & Palestras', href: PATH_PAGE.cursos },
                  { name: titlePage },
                ]}
              />
            </Stack>

            <StyledButtonGroup>
              <ButtonGroup variant="contained">
                {Object.keys(coursesData).map((category) => (
                  <Button
                    key={category}
                    variant={activeButton === category ? 'contained' : 'soft'}
                    onClick={() => handleButtonClick(category)}
                  >
                    {category}
                  </Button>
                ))}
              </ButtonGroup>
            </StyledButtonGroup>

            <StyledContainerCourse>
              {activeButton &&
                coursesData[activeButton as keyof typeof coursesData].map((course: any) => (
                  <CourseCard
                    src={course.src}
                    alt={course.alt}
                    title={course.title}
                    buttonTitle={course.buttonTitle}
                    link={course.link}
                    description={course.description}
                    topics={course.topics}
                    live={course.live}
                    recorded={course.recorded}
                    liveDescription={course.liveDescription}
                    value={course.value}
                    installments={course.installments}
                    valueInstallments={course.valueInstallments}
                    totalHours={course.totalHours}
                  />
                ))}
            </StyledContainerCourse>
          </Stack>

          {/* <Stack alignSelf="center" padding={{ xs: '20px', sm: '0px' }}>
            <Typography variant="h4" align="center" mb={4}>
              Perguntas Frequentes
            </Typography>

            <AccordionList content={accordionContent} />
          </Stack> */}
        </TextContainer>

        <WhatsAppPopup />
      </StyledBox>
    </>
  );
}

import { Divider, Stack, Typography } from '@mui/material'; // Assumindo que você está usando @mui/material
import { Animate } from 'src/features/global/styles';

// ---------------------------------------------------------------

interface LinkFormattedProps {
  content: {
    src: string;
    label: string;
  }[];
}

interface TypographyFormattedProps {
  label: string;
}

// ---------------------------------------------------------------

export function LinkFormatted({ content }: LinkFormattedProps) {
  return (
    <Stack spacing={2}>
      {content.map((data) => (
        <Animate>
          <a
            href={data.src}
            style={{ textDecoration: 'none', color: '#FFF', marginTop: '5px', textAlign: 'center' }}
          >
            {data.label}
          </a>
        </Animate>
      ))}

      <br />
      <br />
    </Stack>
  );
}

// ---------------------------------------------------------------

export function TypographyFormatted({ label }: TypographyFormattedProps) {
  const light = '#FFF';

  return (
    <>
      <Typography gutterBottom variant="h5" mt={2} color="#FEA550" align="center">
        {label}
      </Typography>

      <Divider sx={{ marginBottom: '10px', marginTop: '10px', backgroundColor: '#FEA550' }} />
    </>
  );
}

import { Stack, styled } from '@mui/material';
import Iconify from 'src/components/iconify';
import { Animate } from 'src/features/global/styles';
import { PATH_CONTACT } from 'src/routes/paths';

// ------------------------------------------------------------------

const StyledContainer = styled(Stack)(() => ({
  position: 'fixed',
  bottom: '10px',
  right: '20px',
  padding: '20px',
}));

// ------------------------------------------------------------------

export default function WhatsAppPopup() {
  const handleOpenWhatsApp = () => {
    window.open(`${PATH_CONTACT.whatsApp}`, '_blank');
  };

  return (
    <>
      <StyledContainer>
        <Animate>
          <Iconify icon="logos:whatsapp-icon" width={50} onClick={handleOpenWhatsApp} />
        </Animate>
      </StyledContainer>
    </>
  );
}

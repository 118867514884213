import { Button, Stack } from '@mui/material';
import ScrollToTop from 'src/components/scroll-to-top/ScrollToTop';
import ContainerRow from './containerRow';
import CopyRight from './copyright';
import Social from './social';
// ---------------------------------------------------------------------------

export default function Footer() {
  return (
    <Stack style={{ backgroundColor: '#222222' }}>
      <Social />

      <ContainerRow />

      <CopyRight />
    </Stack>
  );
}
